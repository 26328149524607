import React from 'react';

export default function NewsletterUnis({ title, subTitle }) {
    return (
        <div className="cs_newsletter_1_wrap">
            <div className="container">
                <div className="cs_newsletter unis">
                    <img width="75px"  src="/images/home_1/u1.png" alt="Icon" />
                    <img width="270px"  src="/images/home_1/u2.png" alt="Icon" />
                    <img width="75px"  src="/images/home_1/u3.png" alt="Icon" />
                    <img width="165px"  src="/images/home_1/u4.png" alt="Icon" />
                </div>
            </div>
        </div>
    );
}
