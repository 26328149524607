import React, { useState } from 'react';
import Rating from '../Rating';

export default function Testimonial() {
  const [activeTab, setActiveTab] = useState(2);
  const handleTabClick = tabNumber => {
    setActiveTab(tabNumber);
  };
  return (
    <div className="cs_tabs cs_style1">
      <ul className="cs_tab_links">
        <li className={activeTab === 1 ? 'active' : ''}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(1)}>
            <div className="cs_testimonial_1_avatar">
              <img src="/images/home_1/avatar_1.png" alt="Avatar" />
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">Rocío Narváez</h3>
                <p className="cs_text_web_color mb-0">Quito, ECUADOR</p>
              </div>
            </div>
          </div>
        </li>
        <li className={activeTab === 2 ? 'active' : ''}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(2)}>
            <div className="cs_testimonial_1_avatar">
              <img src="/images/home_1/avatar_2.png" alt="Avatar" />
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">Natasha Oviedo</h3>
                <p className="cs_text_web_color mb-0">Galápagos, ECUADOR</p>
              </div>
            </div>
          </div>
        </li>
        <li className={activeTab === 3 ? 'active' : ''}>
          <div className="cs_tab_link_in" onClick={() => handleTabClick(3)}>
            <div className="cs_testimonial_1_avatar">
              <img src="/images/home_1/avatar_3.png" alt="Avatar" />
              <div className="cs_testimonial_1_avatar_right">
                <h3 className="cs_fs_24 cs_semibold mb-0">Danny Obando</h3>
                <p className="cs_text_web_color mb-0">Tulcán, ECUADOR</p>
              </div>
            </div>
          </div>
        </li>
      </ul>
      <div className="cs_tab_body">
        {activeTab === 1 && (
          <div className="cs_testimonial cs_style_1 ">
            <img src="/images/icons/quote.svg" alt="Icon" />
            <p className="cs_text_web_color">
              Excelente profesional muy capacitada en su área de especialidad y
              con un don de servicio humanitario extraordinario. Gracias Dra.
              Estrella.
            </p>
            <Rating ratingNumber={5} />
          </div>
        )}
        {activeTab === 2 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon"/>
            <p className="cs_text_web_color">
              Excelente especialista! 100% recomendada, desde el primer día
              de tratamiento setí la diferencia, muchas gracias Doctora.
            </p>
            <Rating ratingNumber={5} />
          </div>
        )}
        {activeTab === 3 && (
          <div className="cs_testimonial cs_style_1">
            <img src="/images/icons/quote.svg" alt="Icon" />
            <p className="cs_text_web_color">
              Excelente persona...con gran profesionalismo y sobre todo seguridad
              en todos su procedimientos...
            </p>
            <Rating ratingNumber={5} />
          </div>
        )}
      </div>
    </div>
  );
}
