import { Icon } from '@iconify/react';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from "axios";


export default function AppointmentForm() {
  const [selectedDate, setSelectedDate] = useState(null);

  const URL_API = `${process.env.REACT_APP_URL_LOCAL_API}`;

  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevents default form submission behavior

    //  const dataToSubmit = {
    //    nombre: "Fernando Estrella",
    //    email: "fernandoestre@hotmail.com",
    //    telefono: "0993615846x",
    //  };
    //
    // try {
    //   const response = await axios.post(URL_API, dataToSubmit, {
    //     headers: {
    //       "Content-Type": "multipart/form-data",
    //     },
    //   });
    //
    //
    //   console.log("Res> " + response.data);
    //
    //   // if(response.data === true){
    //   //   toast.success("Cita registrada correctamente.");
    //   // }else{
    //   //   toast.error("Error al enviar la cita.");
    //   //   setResult("Error al enviar el correo electrónico");
    //   //   res("Error al enviar el correo electrónico");
    //   // }
    //
    // } catch (error) {
    //   console.error("Error al agregar amigo:", error);
    // }


    // // Call any necessary submission methods here
    // // ...
    // history.push('/my-new-page'); // Redirect to new page
  }


  return (
    <form onSubmit={handleSubmit} className="row"  method="POST">
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">Nombre</label>
        <input type="text" className="cs_form_field" placeholder="Nombre y Apellido" />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">Número de teléfono</label>
        <input
          type="text"
          className="cs_form_field"
          placeholder="(123) 456 - 789"
        />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">
          Correo Electrónico
        </label>
        <input
          type="email"
          className="cs_form_field"
          placeholder="mail@dominio.com"
        />
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">
          Fecha preferida
        </label>

        <div className="cs_with_icon_input">
          <DatePicker
            className="cs_pl_45"
            selected={selectedDate}
            onChange={date => setSelectedDate(date)}
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
            isClearable
            placeholderText="dd/mm/yyyy"
          />
          <i>
            <Icon icon="fa6-solid:calendar-days" />
          </i>
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-6">
        <label className="cs_input_label cs_heading_color">
          Hora preferida
        </label>
        <div className="cs_with_icon_input">
          <input
            type="time"
            className="cs_form_field cs_timepicker cs_pl_45"
            placeholder="10:00AM"
          />
          <i>
            <Icon icon="fa6-regular:clock" />
          </i>
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">
          Motivo de la cita
        </label>
        <div className="cs_radio_group">
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="reasonForVisit"
              id="routineCheckup"
              defaultValue="routineCheckup"
            />
            <label className="cs_radio_label" htmlFor="routineCheckup">
              Chequeo de Rutina
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="reasonForVisit"
              id="newPatientVisit"
              defaultValue="newPatientVisit"
              defaultChecked=""
            />
            <label className="cs_radio_label" htmlFor="newPatientVisit">
              Nuevo Paciente
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="reasonForVisit"
              id="specificConcern"
              defaultValue="specificConcern"
            />
            <label className="cs_radio_label" htmlFor="specificConcern">
             Preocupación Específica
            </label>
          </div>
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-12">
        <label className="cs_input_label cs_heading_color">Procedimiento</label>
        <div className="cs_radio_group">
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="department"
              id="pediatric"
              defaultValue="pediatric"
            />
            <label className="cs_radio_label" htmlFor="pediatric">
              Otoplastia
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="department"
              id="obstetricsGynecology"
              defaultValue="obstetricsGynecology"
              defaultChecked=""
            />
            <label className="cs_radio_label" htmlFor="obstetricsGynecology">
              Rinoplastia
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="department"
              id="cardiology"
              defaultValue="cardiology"
            />
            <label className="cs_radio_label" htmlFor="cardiology">
              Mentoplastia
            </label>
          </div>
          <div className="cs_radio_wrap">
            <input
              className="cs_radio_input"
              type="radio"
              name="department"
              id="neurology"
              defaultValue="neurology"
            />
            <label className="cs_radio_label" htmlFor="neurology">
              Otro
            </label>
          </div>
        </div>
        <div className="cs_height_42 cs_height_xl_25" />
      </div>
      <div className="col-lg-12">
        <button className="cs_btn cs_style_1">
          <span>Enviar</span>
          <i>
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
            <img src="/images/icons/arrow_white.svg" alt="Icon" />
          </i>
        </button>
      </div>
    </form>
  );
}
