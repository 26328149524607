import React from 'react';
import SectionHeading from '../../SectionHeading';
import Spacing from '../../Spacing';
import Feature from '../../Feature';
import Fade from "react-reveal/Fade";

export default function FeaturesSection({ sectionTitle, data }) {
  return (
    <div className="cs_shape_wrap">
      <div className="cs_shape_1" />
      <div className="container">
        <SectionHeading title={sectionTitle} center />
        <Spacing md="72" lg="50" />
          <Fade  bottom duration={1500}>
            <div className="cs_random_features">
              {data?.map((item, index) => (
                <div className="cs_random_features_col" key={index} >
                  <Feature {...item} />
                </div>
              ))}
            </div>
          </Fade>
      </div>
    </div>
  );
}
