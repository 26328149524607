import { Icon } from '@iconify/react';
import React from 'react';
import parse from "html-react-parser";

export default function ContactDocInfoWidget({ name, phone, mail, address }) {
    return (
        <ul className="cs_contact_widget">
            <li>
                <p className="cs_footer_dr_name cs_fs_color_white">{parse(name)}</p>
            </li>
            <li>
                <i className="cs_accent_bg">
                    <Icon icon="fluent:call-24-regular" />
                </i>
                <span className="cs_fs_color_white">{parse(phone)}</span>
            </li>
            <li>
                <i className="cs_accent_bg">
                    <Icon icon="bi:envelope" />
                </i>
                <span className="cs_fs_color_white">{parse(mail)}</span>
            </li>
            <li>
                <i className="cs_accent_bg">
                    <Icon icon="ep:location" />
                </i>
                <span className="cs_fs_color_white"> {parse(address)}</span>
            </li>
        </ul>
    );
}
