import React from 'react';
import ContactDocInfoWidget from '../Widget/ContactDocInfoWidget';
import MenuWidget from '../Widget/MenuWidget';
import SocialNetWidget from '../Widget/SocialNetWidget';
import Newsletter from '../Widget/Newsletter';
import TextWidget from '../Widget/TextWidget';
import NewsletterStyle2 from "../Widget/NewsletterStyle2";
import NewsletterUnis from "../Widget/NewsletterUnis";
import parse from "html-react-parser";
import {Link} from "react-router-dom";
import {Icon} from "@iconify/react";
/*const menuDataOne = [
  { title: 'Inicio', href: 'javascript:window.location.href=window.location.href' },
  { title: 'Quiénes somos', href: '/xabout' },
  { title: 'Procedimientos', href: '/xdepartments' },
  { title: 'Testimonios', href: '/xdoctors' },
  { title: 'Turismo Médico', href: '/xtimetable' },
  { title: 'Contacto', href: '/xappointments' },
];
const menuDataTwo = [
  { title: 'Citas', href: '/xblog' },
  { title: 'Contáctanos', href: '/xcontact' },
  { title: 'FAQs', href: '/' },
  { title: 'Política de Privacidad', href: '/' },
  { title: 'Términos y Condiciones', href: '/' },
];*/
const menuDataTwo = [
  { title: 'Aceptamos tarjetas de crédito', href: '/' },
  { title: 'Política de Privacidad', href: '/' },
  { title: 'Términos y Condiciones', href: '/' },
];

export default function Footer() {
  return (
    <footer className="cs_footer cs_style_2 cs_primary_color_bg cs_heading_color cs_footer_mt150">
      <NewsletterUnis
      />
      {/*      <div
        className="cs_footer_logo_wrap"
        style={{ backgroundImage: 'url(/images/footer_bg_1.svg)' }}
      >
        <div
          className="cs_footer_brand"
          style={{ backgroundImage: 'url(/images/footer_logo_bg.svg)' }}
        >
          <img
            src="/images/logo_icon.svg"
            alt="Logo Icon"
            className="cs_footer_brand_icon"
          />
          <h2 className="cs_footer_brand_text">ProHealth</h2>
        </div>
      </div>*/}
      <div className="container">
        <div className="cs_footer_in">
          <div className="cs_footer_col" style={{width:"40%"}}>
            <div className="cs_footer_item">
              <div className="cs_text_widget">
                  {/*<p className="cs_medium cs_fs_color_white">{parse("Consultorio México")}</p>*/}
              </div>
              <ContactDocInfoWidget name="Dr. Rodrigo Fernández Pellón"
                                    phone="(+56) 3890 6177"
                                    mail="otorrinopellon@gmail.com"
                                    address="C. Querétaro 58 consultorio 403, Roma Nte., Cuauhtémoc,06700 Ciudad de México, CDMX"/>
            </div>
          </div>

          <div className="cs_footer_col" style={{width:"40%"}}>
            <div className="cs_footer_item">
              <div className="cs_text_widget">
                  {/*<p className="cs_medium cs_fs_color_white">{parse("Consultorio Ecuador")}</p>*/}
              </div>
              <ContactDocInfoWidget name="Dra. Gabriela Estrella Ortega"
                                    phone="(+593) 98 3101 132"
                                    mail="otorrinopellon@gmail.com"
                                    address="C. Querétaro 58 consultorio 403, Roma Nte., Cuauhtémoc,06700 Ciudad de México, CDMX"/>
            </div>
          </div>

          <div className="cs_footer_col" style={{width:"20%"}}>
            <div className="cs_footer_item">
                <div className="cs_credit_card_links">
                <img src="/images/home_1/visa.svg" alt="visa" />
                <img src="/images/home_1/mastercard.svg" alt="mastarcard" />
                <img src="/images/home_1/american.svg" alt="american" />
              </div>
              <ul className="cs_menu_widget cs_fs_color_white cs_mp0 cs_fs_small">
                  <li>
                    <Link to={menuDataTwo[0].href} style={{fontSize:"small"}}>{menuDataTwo[0].title}</Link>
                  </li>
                  <li>
                    <Link to={menuDataTwo[1].href}>{menuDataTwo[1].title}</Link>
                  </li>
                  <li>
                    <Link to={menuDataTwo[2].href}>{menuDataTwo[2].title}</Link>
                  </li>
              </ul>
            </div>
          </div>

        </div>
      </div>
      {/*
      <div className="cs_footer_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <TextWidget text="ProHealth Medical & <br />Healthcare Center" />
                <ContactInfoWidget />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="cs_footer_item">
                <MenuWidget data={menuDataOne} />
              </div>
            </div>
            <div className="col-lg-2">
              <div className="cs_footer_item">
                <MenuWidget data={menuDataTwo} />
              </div>
            </div>
            <div className="col-lg-4">
              <div className="cs_footer_item">
                <Newsletter
                  title="Be Our Subscribers"
                  subTitle="To get the latest news about health from our experts"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      */}


      <div className="cs_footer_bottom_custom">
        <div className="container">
          <div className="cs_footer_custom">
            <SocialNetWidget />
            <div className="cs_copyright cs_fs_color_white">
              Copyright © 2024. Todos los derechos reservados.
            </div>
          </div>
        </div>
      </div>

    </footer>
  );
}
